.contactPage {

    display: flex;
}


@media only screen and (max-width: 600px) { 
    .contactPage {
        flex-direction: column-reverse;
    }
    #contactSvg {
        width: 100%;
        height: 500px;
    }
    
}