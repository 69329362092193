.Modal {
    position:fixed;
    top:0; 
    background: rgba( 255, 255, 255, 0.9 );
    -webkit-box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
            box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    min-height: 100vh;
    min-width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 100;
    
}
.closeBtn {
   
    text-align: right;
    position: absolute;
    top:12px;
    right:80px;
}

.closeBtn:hover {
  color:var(--color-secondary)
}
.groupbtn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.CancelPortalBtn {
    margin-top:20px;
    width: 50%;
}
.joinPortalBtn {
    margin-top:20px;
    width: 50%;
    height: 50px;
}
.modalContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.modalSelectedProgram {
    margin-right: 150px;
}

@media only screen and (max-width: 600px) {
    .Modal {
        position:fixed;
        top:0; 
        bottom:0;
        left:0;
        right:0;
        
    }
    .closeBtn {
   
        text-align: right;
        position: absolute;
        top:480px;
        right:80px;
        font-size: 100px;
    }
    .modalContainer {
        flex-direction: column-reverse;
    }
    .modalSelectedProgram {
        margin-right: 0px;
    }
 }