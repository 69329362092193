.termsPolicyContainer {
    margin-top:80px;
    margin-right:123px;
    margin-left:123px;

}

@media only screen and (max-width: 600px) { 
    .termsPolicyContainer {
        margin-top:0px;
        margin-right:12px;
        margin-left:12px;
    
    }

}