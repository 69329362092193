
.aboutUsContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin:10px 10px ;

}
.aboutusPara {
    width: 50%;
    text-align: center;
    font-weight: bold;
}
.yogaPoseAboutUs {

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width:700px;
}
.yogaposeTitle {
    width: 250px;
    font-weight: bold;
}
.yogapostImageContainer {
    width:500px;
    height: 100%;
}
.yogapostImageContainer > img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.ourmissionPara {

    border-bottom: 1.5px solid gray;
}

.SpecialContainer {
    margin-top:80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: rgba( 255, 255, 255, 0.9 );
    -webkit-box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 8.5px );
    -webkit-backdrop-filter: blur( 8.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.signUpButton {
    
    width: 140px;
    padding-top:10px;
    padding-bottom: 10px;
   
}
 

.specialCol1 {
    padding-left:20px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 300px;
}
.specialCol2 {
    width: 500px;
    background-image: url('../Assets/Images/child.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.specialCol2> img {
    -o-object-fit: cover;
       object-fit: cover;
}

@media only screen and (max-width: 600px) { 
    .aboutUsContainer {
        margin:10px 10px ;
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
        text-align: left;
      
    }
    .yogaPoseAboutUs {
        align-items: flex-start;
        gap:23px;
        margin-left:12px;
        width: 100%;
     
        flex-direction: column;
    }
    .yogaposeTitle {
        width: 50px;
        margin:0;
        font-weight: bold;
    }
    .yogapostImageContainer ,.arrowP{
        width:350px;
        height: 100%;
    }
    .aboutusPara {
        width: 50%;
        text-align: center;
        font-weight: bold;
    }
    
    .SpecialContainer {
        height: 100%;
        width: 100%;
        flex-direction: column;
    }
    .aboutusPara {
        width: 100%;
        padding-right:32px;
        padding-left:32px;
        text-align: left;
        font-weight: bold;
    }
    footer {
        margin-top:0;
        margin:0;
        width: 100%;
    }
}   