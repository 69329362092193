footer {
    margin-top: 150px;
}

.pageFooter {
    
    display: flex;
    align-items: top;
    justify-content: space-evenly;
}

.footerRight {
    width: 60%;
}

@media only screen and (max-width: 600px) { 
    .pageFooter {
        display: flex;
        flex-direction: column;
        align-items: top;
        justify-content: space-evenly;
    }
    .footerRight {
        width: 100%
    }
    
}