
:root  {
    --color-primary: #d1cdcd;
    --color-primary2: #f0eeee;
    --color-secondary: #F29441;
    --color-third: #5C82F2; 
    --color-fourth: #295BF2; 
    --color-fourth-hover: #6a8cf0; 
    
}
html {font-size: 100%;} /*16px*/

/* html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
} */



/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
    background-color: var(--color-primary); 
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.7;

}
ol, ul {
	list-style: none;
  line-height: 2;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
  text-decoration: none;
  color:unset;
}

a:hover {
  color:var(--color-fourth)
}





p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  margin: 3rem 0 1.38rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 4.209rem;
}

h2 {font-size: 3.157rem;}

h3 {font-size: 2.369rem;}

h4 {font-size: 1.777rem;}

h5 {font-size: 1.333rem;}
h6 {
  font-weight: bold;
}

small, .text_small {font-size: 0.75rem;}


.SpecialColor {
  color:var(--color-secondary)
}

button {
  
  border:none;
  color:white;
  background-color: var(--color-fourth);
  border-radius: 10px;
}

button:hover {
  background-color: var( --color-fourth-hover);
}

form {
  display: flex;
  width: 400px;
  flex-direction: column;
}

input {
  height: 40px;
  padding:5px;
  outline: none;
  border-radius: 10px 10px;
}

input::placeholder {
  font-weight: bold;
  color:black;
  border-radius: 20px 20px;
}
.btnFormContactPage {
  margin-top:20px;
  width: 100%;
  height: 50px;
  text-align: center;
}
textarea {
  border-radius: 10px 10px;
}
textarea::placeholder{
  padding-top:20px;
  color:black;
  font-weight: bold;
}
label { 
  margin-top:20px;
  margin-bottom:10px;
}

@media only screen and (max-width: 600px) {  

  body {
    margin-left:15px;
  
  }
}