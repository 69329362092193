.LandingPage {

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.meduaSection {

display: -webkit-box;

display: -ms-flexbox;

display: flex;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
-webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
}

.medusaText {
    width: 500px;
    margin-right: 100px;
    padding-left: 50px;
}

.medusaImageContainer {
    -webkit-animation: borderMedua infinite ease-in-out 5s  ;
    animation: borderMedua infinite ease-in-out 5s  ;
    height: 1000px;
    width: 100%;
}

@-webkit-keyframes borderMedua {
    0%
    {
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
        linear-gradient(85deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
    }
    30% {
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
        linear-gradient(145deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
    }
    50% {
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
        linear-gradient(240deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
}
    80%{
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
        linear-gradient(320deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
    }
    100% {
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
            radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
            radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
            radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
            linear-gradient(28deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
    }
}

@keyframes borderMedua {
    0%
    {
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
        linear-gradient(85deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
    }
    30% {
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
        linear-gradient(145deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
    }
    50% {
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
        linear-gradient(240deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
}
    80%{
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
        linear-gradient(320deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
    }
    100% {
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/7px 7px no-repeat,
            radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/7px 7px no-repeat,
            radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/7px 7px no-repeat,
            radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/7px 7px no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 14px) no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 8px) no-repeat,
            linear-gradient(28deg, transparent 0%, #f29441 100%);
border-radius: 7px;
padding: 7px;
box-sizing: border-box;
    }
}
.medusaImageContainer >img {
   
    width: 100%;
    height: 100%;
}
.banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    margin-top: 50px;
    margin-bottom:50px;
    width: 900px;
    background: rgba(255, 255, 255, 1);
    border-radius: 16px;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(192, 187, 187, 0.95);
    -webkit-box-shadow: -26px 15px 0px 0px rgba(244, 244, 244, 1);
    box-shadow: -26px 15px 0px 0px rgba(244, 244, 244, 1);
} 
.imagebanner {
    overflow-x: visible;
    position: relative;
    left:100px;
}
.textBanner {
    width: 70%;
    margin-left:5px;
    font-weight: bold;
}
.programs {
    margin-bottom: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
   
}

.savesoilLeft {
    margin-right: 100px;
}
.textDescription {
    width: 50%;
    -ms-flex-item-align: left;
        -ms-grid-row-align: left;
        align-self: left;
}

.imagesavesoil > img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
}
.joinButton {
    width: 100px;
    background-color: transparent;
    border:1px solid gray;
    color:black;
    height: 50px;
}
.joinButton:hover {
    background-color: white;
    border:1px solid var(--color-third);
    color:var(--color-secondary)
}
.partnerCard {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
}
.partneritems {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.innerCards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 500px;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.86);
    border-radius: 16px;
    -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(14.5px);
    -webkit-backdrop-filter: blur(14.5px);
    border: 1px solid rgba(255, 255, 255, 0.76);
}
.partnerImageLogo {
    background: rgba( 255, 255, 255, 0.55 );
-webkit-box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
    width: 150px;
    height: 150px;
}
.partnerImageLogo >img {
    border-radius: 16px;
 
    width: 100%;
    height: 100%;
}
.MusicPlayContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    /* width: 600px; */
   background-color: white;
   border-radius: 16px;
   -webkit-box-shadow: -2px 30px 8px 1px #ffffffab;
           box-shadow: -2px 30px 8px 1px #ffffffab;
    margin-top:200px;

}
.firstColumn {
    margin-left: 43px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.subPrograms {
    padding-right: 20px;
    padding-left: 20px;

    width: 500px;
}
.subprogramHL {
    width: 400px;
}
.MusicBtnJoin {
    width: 100px;
    height: 50px;
  }
  .firtcolImage {
    width: 500px;
    height: 400px;
  }

  .firtcolImage > img {
    position: relative;
    bottom:150px;
    width: 100%;
    height: 140%;
  }


  @media only screen and (max-width: 600px) { 

        .meduaSection {
            flex-direction: column;
        }
        .medusaText {
            width: 100%;
            font-size: 20px;
            margin-right: unset;
            padding-left: 50px;
        }

        .medusaImageContainer {
            height: 500px;
            width: 100%;
        }
        .banner {
           
            flex-direction: column;
            width: unset;
            font-size: 20px;
            padding-left: 23px;
            padding-right: 23px;

         }
         
         .imagebanner {
            position: unset;
            width: 100%;
            height: 100%;
            
        }
        .textBanner {
            width: 100%;
            margin-left:0px;
            font-weight: bold;
        }
        .imagebanner > img {
            position: unset;
            width: 100%;
            height: 100%;
        }
        .programs {
            border:1px solid gray;
            padding-bottom: 23px;
            flex-direction: column;
            align-items: center;
            justify-content:center;
        }
        .savesoilLeft {
            margin-right: 0px;
        } 
        .yogaPoster {
            margin-bottom: 0px;
        }
        .programReverse {
            flex-direction: column-reverse;
        }
        .textDescription  {
            width: unset;
            margin-bottom: 50px;
        }
        .textDescriptionsReverse {
            width: 80%;
            margin-bottom: 50px;
        }
        .partnerCard {
           flex-direction: column;
           align-items: center;
        }
        .partneritems, .innerCards{
            width: 100%;
            flex-direction: column;
        }
        .MusicPlayContainer{
            flex-direction: column;
            align-items: center;
            
        }
        .subPrograms{
            padding:0;
            width: 300px;
        }
        .firtcolImage {
            width: 80%;
            height: 250px;
           
          }
        .firstColumn {
            margin:unset;
            width: 100%;
           
        }

          .firtcolImage > img {
            position: relative;
            bottom:150px;
            width: 100%;
            height: 160%;
          }
        
  }