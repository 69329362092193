.Navigation {

    
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
   
}

.NavLinkList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding-left:50px;
    
}
.NavLinkItems {
    font-weight: bold;
}

.logoTextPic {
  
    font-size: xxx-large;
    font-weight: bolder;
   
    width: 08%;
  
}
.logoTextPic > img {
    width: 100%;
    height: 100%;
    margin:0;
    padding:0;
 
}

.logoTextPic:hover {

    -webkit-animation: changeTextPic 10s ease-in 1;

            animation: changeTextPic 10s ease-in 1;
    -webkit-transition: all ease-in;
    -o-transition: all ease-in;
    transition: all ease-in;

}

@-webkit-keyframes changeTextPic {
    0%{
       
        background-image: url(../Assets/Images/cawLogo_black.png);
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
       
    }
    50%{
        opacity: 0.7;
        background-image: url(../Assets/Images/cawLogo_black.png);
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
     
    }
    100%{
        
        opacity: 1;
        background-image: url(../Assets/Images/cawLogo_black.png);
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        
    }
}

@keyframes changeTextPic {
    0%{
       
        background-image: url(../Assets/Images/cawLogo_black.png);
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
       
    }
    50%{
        opacity: 0.7;
        background-image: url(../Assets/Images/cawLogo_black.png);
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
     
    }
    100%{
        
        opacity: 1;
        background-image: url(../Assets/Images/cawLogo_black.png);
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        
    }
}
.groupOneNav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    width: 35%; 
   
}

.ToggleNav {
    display: none;
}

@media only screen and (max-width: 600px) {
    .Navigation {
        height: 20px;
    }
    .NavLinkList {
        display: none;
    }
   .ToggleNav{
    position: fixed;
    z-index: 20999;
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    bottom:50px;
    right:50px;
    background: rgba( 255, 255, 255, 0.2 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 16px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
   }
   
   .MobNavLinkList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
    z-index: 10999;
    border:1px solid black;
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    margin:0;
    background: rgba( 255, 255, 255, 0.55 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
    width: 100vw;
    height: 110vh;
   }
   .groupOneNav {
    display: flex;
    flex-direction: column;
   }
   .mobNavLinkItems {
    line-height: 130px;
    font-size: 25px;
   }
   .logoTextPic {
    width: 100%;
  
}
  }