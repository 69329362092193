.programsPage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

}

.dailyworkoutsection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.programsList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 1300px;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    margin:23px;
    padding:20px;
    background: rgba( 255, 255, 255, 0.8 );
    -webkit-box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
            box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 19.5px );
    -webkit-backdrop-filter: blur( 19.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.progJoinBtn {
    width: 100px;
    height: 40px;

    
}
.programsTitle {
    text-align: center;
    height: 350px;
    padding:12px;
    line-height: 0.1;
    background: rgba( 255, 255, 255, 0.8 );
    -webkit-box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
            box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 19.5px );
    -webkit-backdrop-filter: blur( 19.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.programDetails {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 1300px;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    margin-bottom: 50px;
}

.programDetailsList {
    margin:0;
    padding:0;
}
.dailyWorkoutPara {
    padding-right: 100px;
    padding-left: 100px;
}
.programDetailsListIcons {
    fill:blue;
}

@media only screen and (max-width: 600px) { 

    .programsPage, .programsList, .programDetails {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .programsList {
        width: 80%;
        margin:15px;
        padding:5px;
    }
    .programsTitle {
        text-align: center;
        margin:12px;
        width: 80%;
        padding-bottom: 12px;
    }
    .runningImage {
        width: 100%;
    }
    .runningImage > img {
        width: 100%;
    }
    .dailyWorkoutPara {
        padding-right: 10px;
        padding-left: 20px;
    }
    .programDetails { 
      
        width: 100%;
    }
}