.dashboard {
    display: flex;
    align-items: center;
    justify-content: space-between;
 
    
   
}
.dashboardSide {
    display: flex;
    padding:12px;
    gap:50px;
    flex-direction: column;
    align-self: flex-start;

    user-select: none;
    background: rgba( 255, 255, 255, 0.6 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.dashboardSide > div {

    border-bottom:none;
    transition: all 2s;
}
.dashboardSide > div:hover {
    cursor: pointer;
    border-bottom:1px solid black;

}

.active {
    color:blue;
}
.dashboardMain {
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;
    flex-wrap: wrap;
}

.Subscriber {
    padding:12px;
    border:1px solid black;
    background: rgba( 255, 255, 255, 0.6 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.customerMessage {
    width: 500px;
    word-wrap: break-word;
}

.subscriberListItems {
    font-weight: bolder;
}

@media only screen and (max-width: 600px) {  
    .dashboard {
       flex-direction: column;    
    }
    .dashboardSide {
        display: flex;
        flex-direction: row;
        border:1px solid gray; 
        width: 100%;
        justify-content: space-evenly;
    
    }

    .customerMessage {
        width: 300px;
        padding:15px;
    }
}